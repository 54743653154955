/* General styles */
.large-green-ellipse,
.dark-green-medium-circle,
.small-green-circle,
.dark-green-half-donut,
.medium-yellow-circle,
.dark-yellow-circle-small,
.large-green-semicircle {
  position: absolute;
  width: auto;
  height: auto;
  z-index: 1; /* Ensure shapes are at a lower z-index */
}

.large-green-ellipse { top: 25%; left: 0%; width: 40vw; }
.dark-green-medium-circle { top: 35%; left: 75%; width: 15vw; }
.small-green-circle { top: 32%; left: 75%; width: 5vw; }
.dark-green-half-donut { top: 75%; left: 0%; width: 35vw; }
.medium-yellow-circle { top: 80%; left: 26vw; width: 14vw; }
.dark-yellow-circle-small { top: 55%; left: 15%; width: 4vw; }
.large-green-semicircle { top: 65%; left: 60%; width: 10vw; }

.white-page {
  background-color: #e9e3dc; /* Sets the background color */
  width: 100vw; /* Ensures the width spans the full viewport width */
  position: relative; /* Allows absolute positioning inside */
  padding-bottom: 50px; /* Adds some space at the bottom */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kindervater-position,
.nelson-position {
  width: 100%; /* Full width */
  display: flex;
  justify-content: center; /* Center content horizontally */
  margin-top: 20px; /* Add space between the profiles */
  z-index: 2; /* Ensure profiles are above shapes */
}

/* Responsive design adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .large-green-ellipse { width: 50vw; top: 20%; }
  .dark-green-medium-circle { width: 20vw; top: 30%; left: 70%; }
  .small-green-circle { width: 10vw; top: 28%; left: 70%; }
  .dark-green-half-donut { width: 50vw; top: 70%; }
  .medium-yellow-circle { width: 20vw; top: 75%; left: 20%; }
  .dark-yellow-circle-small { width: 10vw; top: 50%; left: 10%; }
  .large-green-semicircle { width: 15vw; top: 60%; left: 55%; }
  
  .kindervater-position,
  .nelson-position {
    width: 90%; /* Adjust width for tablets */
  }
}

/* Responsive design adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .large-green-ellipse, 
  .dark-green-medium-circle,
  .small-green-circle, 
  .dark-green-half-donut, 
  .medium-yellow-circle, 
  .dark-yellow-circle-small, 
  .large-green-semicircle { 
    display: none;
  }
  
  .white-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .kindervater-position,
  .nelson-position {
    width: 100%;
    margin-top: 20px; /* Add space between profiles */
  }
}
