/* General styles */
.assessment-container {
  background-color: #e9e3dc; /* Set the background color */
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #333;
  position: relative;
  height: 70vh;
}

.total-health-assesments-content h1 {
  margin-top: 5%;
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  color: #36120F;
}

.total-health-assesments-content h2 {
  margin-top: -1%;
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  font-weight: 400;
  line-height: 60px;
  text-align: center;
  color: #36120F;
}

.total-health-assesments-content p {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #36120F;
  margin-bottom: 1em;
}

.total-health-assesments-content button {
  margin-top: 1%;
  padding: 15px 30px;
  font-size: 1.5em;
  border: 2px solid #bb7912;
  color: white;
  background: #bb7912;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
  margin-left: 10px;
}

.total-health-assesments-content button:hover {
  background-color: transparent;
  color: #bb7912;
}

/* Positioning for the images */
.total-health-small-green-circle {
  position: absolute;
  right: 10%; /* Adjust as necessary for alignment */
  top: 35%; /* Adjust as necessary for alignment */
  width: 2.5%;
  height: auto;
}

.total-health-large-green-semicircle {
  position: absolute;
  bottom: -2%; /* Adjust as necessary to partially hide the semi-circle */
  left: -5%; /* Adjust as necessary for alignment */
  width: 40%; /* Adjust based on actual image sizes */
  height: auto;
}

.yellow-circle {
  position: absolute;
  bottom: 12%; /* Adjust as necessary for alignment */
  right: 60%; /* Adjust as necessary for alignment */
  width: 6%; /* Adjust based on actual image sizes */
  height: auto;
}

.total-health-green-half-donut {
  position: absolute;
  bottom: 20%; /* Adjust as necessary to partially hide the semi-circle */
  left: 5%; /* Adjust as necessary for alignment */
  width: 25%; /* Adjust based on actual image sizes */
  height: auto;
}

.yellow-semicircle {
  position: absolute;
  bottom: 8.2%; /* Adjust as necessary for alignment */
  right: 11.2%; /* Adjust as necessary for alignment */
  width: 20%; /* Adjust based on actual image sizes */
  height: auto;
}

.walking-image {
  position: absolute;
  bottom: 10%; /* Adjust as necessary for alignment */
  right: 15%; /* Adjust as necessary for alignment */
  width: 15%; /* Adjust based on actual image sizes */
  height: auto;
}

/* Responsive design adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .total-health-assesments-content h1 {
    font-size: 36px; /* Adjust font size for tablets */
    line-height: 45px;
  }

  .total-health-assesments-content h2 {
    font-size: 36px; /* Adjust font size for tablets */
    line-height: 45px;
  }

  .total-health-assesments-content p {
    font-size: 16px; /* Adjust font size for tablets */
    line-height: 22px;
  }

  .total-health-assesments-content button {
    font-size: 1.2em; /* Adjust font size for tablets */
    padding: 10px 20px; /* Adjust padding for tablets */
    margin-left: 0; /* Center the button on tablets */
  }

  .total-health-small-green-circle,
  .total-health-large-green-semicircle,
  .yellow-circle,
  .total-health-green-half-donut,
  .yellow-semicircle,
  .walking-image {
    width: 30%; /* Adjust sizes for tablets */
  }
}

/* Responsive design adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .total-health-assesments-content h1 {
    font-size: 28px; /* Adjust font size for mobile */
    line-height: 35px;
    margin-bottom: 0%;
  }

  .total-health-assesments-content h2 {
    font-size: 28px; /* Adjust font size for mobile */
    line-height: 35px;
    margin-bottom: 15%;
    
  }

  .total-health-assesments-content p {
    font-size: 20px; /* Adjust font size for mobile */
    line-height: 20px;
    margin-bottom: 10%;
  }

  .total-health-assesments-content button {
    font-size: 1.5em; /* Adjust font size for mobile */
    padding: 8px 16px; /* Adjust padding for mobile */
    margin-left: 0; /* Center the button on mobile */
  }
  
  .total-health-small-green-circle,
  .total-health-large-green-semicircle,
  .total-health-green-half-donut,
  .yellow-semicircle,
  .walking-image {
    width: 20%; /* Adjust sizes for mobile */
  }

  .total-health-green-half-donut{
    bottom: 5%;
    left: 10%;
    width: 30%;
  }


  .total-health-small-green-circle{
    top: 85%;
    left: 85%;
    width: 10%;
  }


  .total-health-large-green-semicircle {
    width: 50%; /* Adjust size for better fit */
  }
  .yellow-circle,
  .yellow-semicircle,
  .walking-image {
    display: none; /* Hide elements on mobile for better layout */
  }
}
