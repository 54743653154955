/* introworks.css */
.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 20px;
    background: #e9e3dc; /* Assuming a light background */
    height: 100vh;
}

.content {
    max-width: 50%;
    padding-left: 10%;
}

.content .member-intro-h1 {
    margin-top: 10px;
    font-size: 46px;
    font-weight: 500;
    color: #36120F;
    line-height: 60px;
    font-family: 'Poppins';
    text-align: left;
    margin-bottom: 0px;
    z-index: 5;
}

.intro-h2 {
    font-family: 'Poppins'; /* Now using Poppins */ 
    color: #36120F;
    font-size: 28px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    padding-bottom: 20px;
    z-index: 5;
}

.content p {
    font-size: 1.2em;
    color: #36120F;
    padding-bottom: 20px;
    text-align: left;
    z-index: 5;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px; /* Adds gap between buttons */
    z-index: 5;
}

.buttons button {
    padding: 15px 30px;
    font-size: 1.5em;
    border: 2px solid #bb7912;
    color: #bb7912;
    background: transparent;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 60px;
    margin-right: 10px;
}

.buttons .contact-us {
    background-color: #bb7912;
    color: white;
}

.buttons .sign-up {
    background-color: transparent;
}

.buttons button:hover {
    background-color: #bb7912;
    color: white;
}

.buttons .contact-us:hover {
    background-color: transparent;
    color: #bb7912;
}

.image-container {
    position: relative;
    width: 45%; /* Adjust based on design */
}

.image-container img {
    width: 100%;
    height: auto;
    display: block;
}

.runner-image,
.overlay-circle,
.decorative-blob {
    position: absolute;
}

.runner-image {
    bottom: 0%; /* Adjust this as needed */
    right: 10%; /* This will place part of the image out of the container, adjust if necessary */
    width: auto; /* Example fixed width, adjust as needed */
    height: 90%; /* Maintains aspect ratio based on the width */
    z-index: 3;
}

.overlay-circle {
    top: 45%; /* Align with the runner image */
    right: 31%; /* This might need adjusting */
    width: 170px; /* Fixed size for the circle */
    height: auto; /* Same as width to maintain the circle shape */
}

.decorative-blob {
    bottom: 0%; /* Adjust position as needed */
    right: 0%;
    width: auto;
    height: 447px;
}

/* Responsive design adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
    .banner {
        flex-direction: column;
        height: auto;
        padding: 20px;
    }

    .content {
        max-width: 100%;
        padding-left: 0;
        text-align: center;
    }

    .content .member-intro-h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .intro-h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .content p {
        font-size: 1em;
        padding-bottom: 15px;
    }

    .buttons {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
    }

    .buttons button {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .image-container {
        width: 80%; /* Adjust based on design */
        margin-top: 20px;
    }

    .runner-image,
    .overlay-circle,
    .decorative-blob {
        position: absolute;
    }

    .runner-image {
        bottom: 10%;
        right: 10%;
        width: 30%;
    }

    .overlay-circle {
        top: 60%;
        right: 5%;
        width: 30%;
    }

    .decorative-blob {
        bottom: 0;
        left: 0;
        width: 30%;
    }
}

/* Responsive design adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
    .banner {
        flex-direction: column;
        height: 80vh;
        padding: 20px;
        padding-top: 7vh;
    }

    .content {
        max-width: 100%;
        padding-left: 0;
        text-align: center;
    }

    .content .member-intro-h1 {
        font-size: 28px;
        line-height: 36px;
    }

    .intro-h2 {
        font-size: 20px;
        line-height: 28px;
    }

    .content p {
        font-size: 0.9em;
    }

    .buttons {
        margin-top: -5vh;
        justify-content: center;
        align-items: center;
        gap: 0px; /* Adds gap between buttons */
    }

    .buttons button {
        margin-left: 0;
        margin-right: 0;
        padding: 10px 20px;
        font-size: 1em;
    }

    .image-container {
        width: 100%; /* Adjust based on design */
        margin-top: 20px;
    }

    .runner-image,
    .overlay-circle,
    .decorative-blob {
        position: absolute;
    }

    .runner-image {
        bottom: -15%;
        right: 0%;
        width: 35%;
        transform: scaleX(-1); /* Flip the image horizontally */
        height: auto;
    }

    .overlay-circle {
        top: 80%;
        right: 70%;
        width: 15%;
    }

    .decorative-blob {
        width: 60%;
        height: auto;
        bottom: 0;
        left: 70%;
        transform: translateX(-50%);
    }
}
