/* TailoredHealthPlans.css */
.tailored-plans-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: auto;
  padding: 20px;
  text-align: center;
  background-color: #f7f4f2;
  max-width: 100%;
  height: auto; /* Adjusted for responsive height */
  padding: 20px; /* Adjusted for better spacing */
}

.health-plans-header h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  line-height: 78px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 2px;
  color: #36120F;
}

.health-plans-header p {
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
  margin-bottom: 1em;
  color: #36120F;
}

.consultations-btn {
  margin-top: 5%;
  padding: 15px 30px;
  font-size: 1.5em;
  border: 2px solid #bb7912;
  color: white;
  background: #bb7912;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
  margin-left: 10px;
}

.consultations-btn:hover {
  color: #bb7912;
  background-color: transparent;
}

.plan-options {
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  align-items: flex-end; /* This aligns the children along the bottom */
}

.plan {
  justify-content: space-between; /* This will push the text to the bottom */
  width: 30%; /* Adjust the width as needed for a responsive design */
  min-width: 250px;
}

.plan p {
  font-size: 20px;
}

.subscription-plan-logo {
  width: 100%; /* Adjust as needed */
}

.plan img {
  width: 60%; /* Adjust as needed */
  height: auto;
  margin-bottom: 20%;
}

/* Responsive adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .plan-options {
    flex-direction: column;
    align-items: center;
  }

  .plan {
    width: 80%;
  }
}

/* Responsive adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {

  .health-plans-header h1 {
    margin-top: 5%;
    font-size: 42px; /* Adjust font size for mobile */
    line-height: 42px;
  }

  .health-plans-header p {
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 24px; /* Adjust font size for mobile */
    line-height: 32px;
  }

  .plan-options {
    flex-direction: column;
    align-items: center;
  }

  .plan {
    width: 90%; /* Full width for mobile */
    margin-bottom: 20px; /* Add spacing between plans */
  }

  .plan img {
    width: 60%; /* Adjust image size for mobile */
    margin-bottom: 10%; /* Adjust margin for mobile */
  }

  .consultations-btn {
    display: none; /* Hide the button on mobile */
  }
}
