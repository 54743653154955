.blog-detail {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.blog-title {
    font-size: 36px;
    margin-bottom: 20px;
    color: #0C3A34; /* Dark Green */
}

.blog-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.blog-content {
    font-size: 18px;
    line-height: 1.6;
    color: #666;
}
