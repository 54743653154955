.blog-page {
    padding: 20px;
    min-height: 100vh;
    background-color: #e9e3dc;
}

.page-title {
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
    color: #0C3A34; /* Dark Green */
}

.blog-holder {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around; /* Ensure even spacing */
}

.blog-card {
    background: #ffffff; /* Update to correct color */
    border: 1px solid black;
    border-radius: 30px;
    overflow: hidden;
    width: calc(50% - 20px); /* Two columns layout with less gap */
    max-width: 600px;
    transition: transform 0.3s ease;
    align-items: center; /* Center align content */
    color: #ffffff; /* Update color for the card text */
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-card .blog-image {
    width: 95%;
    align-self: center;
    margin: auto;
    object-fit: cover;
}

.blog-content {
    padding: 15px;
}

.blog-card .blog-title {
    font-size: 24px;
    margin: 0 0 10px;
    color: #0C3A34; /* Update to the desired color */
}

.blog-card .blog-summary {
    font-size: 16px;
    color: #0C3A34; /* Light Beige */
}

/* Responsive design adjustments */
@media (min-width: 768px) {
    .blog-holder {
        justify-content: space-between;
    }

    .blog-card {
        width: 48%; /* Two columns layout */
    }
}

@media (max-width: 767px) {
    .blog-holder {
        flex-direction: column;
        align-items: center;
    }

    .blog-card {
        width: 100%;
        max-width: 100%;
    }
}
