/* General styles for .getting-started-container */
.getting-started-container {
  position: relative;
  width: 100%;
  height: 583px; /* Adjust the height based on your layout needs */
  background-color: #f7f4f2; /* Light background color */
  overflow: hidden;
}

.dark-green-small-circle, 
.dark-yellow-circle, 
.green-half-donut, 
.people-running {
  position: absolute;
}

.contactus-content {
  position: absolute;
  top: 40%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%; /* Adjust width */
}

.contactus-content h1 {
  font-size: 46px;
  font-weight: 500;
  color: #36120F;
  line-height: 60px;
  font-family: 'Poppins'; /* Now using Poppins */
  margin-bottom: 0px;
}

.contactus-content h2 {
  font-size: 46px;
  font-weight: 500;
  color: #36120F;
  line-height: 60px;
  text-align: center;
  margin-top: 0px;
  font-family: 'Poppins'; /* Now using Poppins */
}

.contactus-content p {
  font-size: 18px;
  font-weight: 400;
  color: #36120F;
  line-height: 26px;
  text-align: center;
  margin-top: 0px;
  max-width: 40%;
  font-family: 'Open Sans', sans-serif;
  margin: auto;
}

.contact-us-btn {
  margin-top: 5%;
  padding: 15px 30px;
  font-size: 1.5em;
  border: 2px solid #bb7912;
  color: #bb7912;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
  z-index: 5;
}

.contact-us-btn:hover {
  background-color: #bb7912;
  color: white;
}

.dark-green-small-circle {
  top: 15%; /* Adjust positioning */
  left: 80%; /* Adjust positioning */
  width: 40px;
  height: auto;
}

.dark-yellow-circle {
  top: 20%; /* Adjust positioning */
  left: 10%; /* Adjust positioning */
  width: 30px;
  height: auto;
}

.green-half-donut {
  bottom: 16%; /* Adjust positioning */
  left: 11%; /* Adjust positioning */
  width: 300px;
  height: auto;
}

.people-running {
  bottom: 25%; /* Adjust positioning */
  left: 15%; /* Adjust positioning */
  width: 232px;
  height: auto;
}

/* Responsive design adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .getting-started-container {
      height: auto; /* Allow height to adjust based on content */
  }

  .contactus-content {
      top: 50%; /* Adjust vertical centering */
      transform: translate(-50%, -50%);
      width: 90%; /* Adjust width for better fit */
  }

  .contactus-content h1, 
  .contactus-content h2 {
      font-size: 36px; /* Adjust font size */
      line-height: 48px; /* Adjust line height */
  }

  .contactus-content p {
      font-size: 16px; /* Adjust font size */
      max-width: 100%; /* Allow paragraph to take full width */
  }

  .contact-us-btn {
      font-size: 1.2em; /* Adjust button size */
      padding: 12px 24px; /* Adjust button padding */
  }

  .dark-green-small-circle,
  .dark-yellow-circle,
  .green-half-donut,
  .people-running {
      width: 70%; /* Scale down images */
      height: auto;
      position: static; /* Remove absolute positioning */
      margin: 10px auto; /* Center align and add margin */
  }
}

/* Responsive design adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .getting-started-container {
      height: 60vh;
      width: 100%;
  }

  .contactus-content {
      top: 30%; /* Adjust vertical centering */
      width: 80%; /* Adjust width for better fit */
  }

  .contactus-content h1, 
  .contactus-content h2 {
      font-size: 28px; /* Adjust font size */
      line-height: 36px; /* Adjust line height */
      font-weight: 600;
  }

  .contactus-content p {
      font-size: 18px; /* Adjust font size */
      max-width: 100%; /* Allow paragraph to take full width */
  }

  .contact-us-btn {
      font-size: 1em; /* Adjust button size */
      padding: 10px 20px; /* Adjust button padding */
  }

  
  .dark-yellow-circle{
    display: none;
  }
  .green-half-donut{
    width: 50%;
    height: auto;
    bottom: -5%;
    left: 0%;
    position: absolute; /* Remove absolute positioning */

  }
  .people-running {
      width: 40%; /* Scale down images */
      height: auto;
      position: absolute; /* Remove absolute positioning */
      bottom: 0%;
      left: 7%;

  }
  .dark-green-small-circle{
    display: none;
  }
}
