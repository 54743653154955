/* Testimonials.css */
.testimonials-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: auto;
  text-align: center;
  background-color: #f7f4f2;
  max-width: 100%;
  height: auto; /* Adjust for responsive height */
  padding: 20px; /* Add padding for better spacing */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center;
}

.testimonials-container h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  line-height: 78px;
  font-weight: 500;
  margin-bottom: 1em;
  color: #36120F;
}

.patient-photos {
  display: flex;
  justify-content: center;
  align-items: center; /* This centers the images vertically */
  margin-bottom: 2em;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.patient-photos img {
  width: 60px; /* Adjust the size as needed */
  height: 60px; /* Maintain the same value as width for a circle */
  border-radius: 50%; /* This will make the image round */
  margin: 0 10px 10px 10px; /* Adjust spacing between images as needed */
}

.central-patient-photo {
  width: 100px; /* Larger size */
  height: 100px; /* Keep it the same as width for a circle */
  z-index: 1;
  position: relative;
}

.testimonial-highlight {
  background: #f7f4f2; /* Light grey background, adjust as needed */
  padding: 20px; /* Added padding for spacing */
  border-radius: 10px; /* Rounded corners */
  margin: 20px auto; /* Center and add margin for spacing */
  max-width: 80%; /* Adjust width for better responsiveness */
}

.testimonial-highlight h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #36120F;
}

.patient-details {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 1em;
  font-family: 'Open Sans', sans-serif;
  color: #36120F;
}

blockquote {
  quotes: "";
  font-style: italic;
  margin-bottom: 2em;
  max-width: 80%; /* Adjust width for better responsiveness */
  margin-left: auto; /* Auto margin on both sides */
  margin-right: auto; /* This centers the block */
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #36120F;
}

.testimonial-consultations-btn {
  display: none; /* Hide the button on mobile */

}

/* Responsive adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .patient-photos {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .patient-photos img {
    margin-bottom: 10px;
  }

  .testimonial-highlight {
    padding: 10px;
    max-width: 90%; /* Adjust width for tablets */
  }

  blockquote {
    max-width: 90%; /* Adjust width for tablets */
  }
}

/* Responsive adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .testimonials-container h1 {
    font-size: 28px; /* Adjust font size for mobile */
    line-height: 35px;
  }

  .patient-photos img {
    width: 50px; /* Adjust the size for mobile */
    height: 50px; /* Maintain the same value as width for a circle */
    margin: 5px 5px 10px 5px; /* Adjust spacing between images for mobile */
  }

  .central-patient-photo {
    width: 80px; /* Adjust size for mobile */
    height: 80px; /* Keep it the same as width for a circle */
  }

  .testimonial-highlight {
    padding: 10px; /* Adjust padding for mobile */
    max-width: 95%; /* Adjust width for mobile */
  }

  .testimonial-highlight h2 {
    font-size: 20px; /* Adjust font size for mobile */
    line-height: 25px;
  }

  .patient-details {
    font-size: 16px; /* Adjust font size for mobile */
    line-height: 18px;
  }

  blockquote {
    max-width: 95%; /* Adjust width for mobile */
    font-size: 18px; /* Adjust font size for mobile */
    line-height: 24px;
  }


  .testimonial-consultations-btn {
    display: block; /* Make the button visible */
    margin-bottom: 2%;
    padding: 15px 30px;
    font-size: 1.5em;
    border: 2px solid #bb7912;
    color: white;
    background: #bb7912;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 60px;
    margin-left: 10px;
  }
}
