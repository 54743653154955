.header {
  position: fixed; /* Make the header fixed */
  top: 0; /* Position it at the top */
  width: 100%; /* Make it full width */
  height: 7vh; /* Adjust this value to set the desired height */
  z-index: 1000; /* Ensure it's above other elements */
  padding: 0 20px;
  background: linear-gradient(to bottom, rgba(233, 227, 220, 1) 70%, rgba(233, 227, 220, 0.5) 100%); /* Light gradient transparency */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures elements are pushed to both ends */
}

.logo {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.logo img {
  max-width: 163px;
  height: auto;
  object-fit: contain;
}

.buttonWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ctaButton {
  font-size: 20px;
  padding: 10px 20px;
  font-weight: 500;
  border: 1px solid #bb7912;
  background-color: #bb7912;
  color: white;
  cursor: pointer;
  border-radius: 60px;
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease-in-out; /* Fade-in and fade-out effect */
}

.ctaButton:hover {
  background-color: transparent;
  color: #bb7912; /* Assuming the hover color changes to the button border color */
  font-weight: 600;
  border: 3px solid #bb7912;
}

.fadeIn {
  opacity: 1; /* Show when added */
}

.fadeOut {
  opacity: 0; /* Hide when removed */
}

.dropdownMenu {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.menuIcon {
  color: #002826; /* Assuming there is an icon with this class, color adjusted for light theme */
}

@media (max-width: 768px) {
  .ctaButton {
    display: none;
  }
}

