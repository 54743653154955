/* FirstSection.css */
.service-section {
  background-color: #E9E3DC; /* Adjust based on the image background */
  padding: 100px; /* Increase padding */
  padding-top: 150px;
  display: flex;
  align-items: flex-start; /* Correct alignment property */
  min-height: 200px; /* Adjust based on content size */
  width: 100%;
}

.services-content {
  margin-top: -5%;
  margin-left: 10%;
  text-align: left;
  align-items: flex-start; /* Correct alignment property */
  max-width: 70%; /* Maximum width of the content area */
}

.service-section h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  line-height: 78px;
  font-weight: 500;
  margin-bottom: 2px;
  color: #36120F;
}

.service-section h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  text-align: left;
  color: #36120F;
}

.service-section p {
  max-width: 70%;
  color: #36120F;
  margin-bottom: 20px; /* Space between the paragraph and buttons */
}

.service-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the left */
  gap: 10px; /* Set a positive gap to create space between buttons */
  position: relative;
  margin-left: 0;
}

.service-contact-button {
  margin-top: 5%;
  padding: 15px 30px;
  font-size: 1.5em;
  border: 2px solid #bb7912;
  color: white;
  background: #bb7912;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
  margin-left: 10px;
}

.service-signup-button {
  margin-top: 5%;
  padding: 15px 30px;
  font-size: 1.5em;
  border: 2px solid #bb7912;
  color: #bb7912;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
  margin-left: 10px;
}

.service-contact-button:hover {
  background-color: transparent; /* Gold color for the contact button */
  color: #bb7912;
}

.service-signup-button:hover {
  background-color: #bb7912; /* White background for sign up button */
  color: white; /* Gold text for the sign up button */
}

/* Responsive adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .service-section {
    padding: 50px; /* Adjust padding for tablets */
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align elements */
  }

  .services-content {
    margin-left: 0;
    text-align: left;
    max-width: 80%; /* Adjust width for tablets */
  }

  .service-section h1 {
    font-size: 36px; /* Adjust font size for tablets */
    line-height: 50px;
  }

  .service-section h2 {
    font-size: 24px; /* Adjust font size for tablets */
    line-height: 32px;
  }

  .service-section p {
    max-width: 80%; /* Full width for tablets */
    margin-bottom: 20px; /* Space between the paragraph and buttons */
  }

  .service-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
    gap: 20px; /* Increase gap between buttons */
    margin-left: 0;
  }

  .service-contact-button,
  .service-signup-button {
    width: 80%; /* Full width buttons for tablets */
    margin-left: 0; /* Center align buttons */
  }
}

/* Responsive adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .service-section {
    padding: 5px; /* Adjust padding for mobile */
    padding-top: 10vh;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align elements */
  }

  .services-content {
    margin-left: 0;
    text-align: left;
    max-width: 90%; /* Adjust width for mobile */
  }

  .service-section h1 {
    font-size: 28px; /* Adjust font size for mobile */
    line-height: 35px;
    color: #36120F;
    text-align: left;
  }

  .service-section h2 {
    font-size: 20px; /* Adjust font size for mobile */
    line-height: 28px;
  }

  .service-section p {
    max-width: 100%; /* Full width for mobile */
    margin-bottom: 20px; /* Space between the paragraph and buttons */
  }

  .service-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center align buttons */
    gap: 20px; /* Increase gap between buttons */
    margin-left: 0;
  }

  .service-contact-button,
  .service-signup-button {
    width: 80%; /* Full width buttons for mobile */
    margin-left: 0; /* Center align buttons */
  }
}
