/* General styles for the entire page */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General styles for .more-questions-container */
.more-questions-container {
  position: relative;
  width: 100%;
  height: 50vh;
  background-color: #002826; /* Adjust the background color as needed */
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
}

.more-questions-container img {
  position: absolute;
}

.ellipse-gray {
  top: 60%;
  left: 25%;
  width: 120px; /* Set the width */
  height: auto; /* Maintain aspect ratio */
}

.circle-white {
  top: 20%;
  right: 20%;
  width: 30px; /* Set the width */
  height: auto; /* Maintain aspect ratio */
}

.ellipse-smaller {
  bottom: 30%;
  left: 0%;
  width: auto; /* Set the width */
  height: 40%; /* Maintain aspect ratio */
}

.half-dounut-green {
  bottom: -15%;
  right: 5%;
  width: 450px; /* Set the width */
  z-index: 0;
  height: auto; /* Maintain aspect ratio */
}

.group-photo {
  right: 17%; /* Adjust as needed */
  bottom: 20%;
  width: 130px; /* Set the width */
  height: 130px; /* Set height equal to width to maintain a perfect circle */
  object-fit: cover; /* Ensures the image covers the area without being stretched */
  border-radius: 50%; /* This creates the circle crop */
}

.text-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0; /* Reset margin */
}

.text-container h1 {
  color: white;
  font-size: 46px; /* Adjust as needed */
  font-family: 'Poppins'; /* Now using Poppins */
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  margin: 0; /* Reset margin */
}

.btn-consultation, .btn-faqs {
  margin-top: 5%;
  padding: 15px 30px;
  font-size: 1.5em;
  border: 2px solid #bb7912;
  color: #bb7912;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
  z-index: 5;
  margin-right: 10px; /* Both buttons will have space on the right */
  margin-left: 10px;  /* Both buttons will have space on the left */
}

.btn-consultation {
  background-color: #bb7912; /* Gold color for FAQs button */
  color: white;
}

.btn-consultation:hover {
  background-color: transparent;
  color: #bb7912;
}

.btn-faqs:hover {
  background-color: #bb7912;
  color: white;
}

/* Responsive design adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .more-questions-container {
    height: auto; /* Allow height to adjust based on content */
    padding: 20px; /* Add padding for spacing */
  }

  .ellipse-gray,
  .circle-white,
  .ellipse-smaller,
  .half-dounut-green,
  .group-photo {
    position: static; /* Remove absolute positioning */
    margin: 10px auto; /* Center align and add margin */
    display: block; /* Ensure block display */
    width: 50%; /* Adjust size for smaller screens */
  }

  .text-container {
    position: static;
    transform: none; /* Remove transform */
    width: 100%; /* Full width */
    margin: 20px 0; /* Add margin */
  }

  .text-container h1 {
    font-size: 36px; /* Adjust font size */
    line-height: 48px; /* Adjust line height */
    margin-bottom: 20px; /* Adjust margin */
  }

  .btn-consultation, .btn-faqs {
    font-size: 1.2em; /* Adjust button size */
    padding: 12px 24px; /* Adjust button padding */
    margin-top: 10px; /* Add top margin */
  }
}

/* Responsive design adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .more-questions-container {
    height: 50vh; /* Allow height to adjust based on content */
    padding: 10px; /* Add padding for spacing */
  }

  .ellipse-gray {
    height: 150px;
    width: auto; /* Adjust size for smaller screens */
    top: 50%;
    left: 0;
  }
  .circle-white,
  .ellipse-smaller {
    display: none;
  }
  .half-dounut-green {
    right: 0%;
    bottom: -10%;
    width: 60%;
    height: auto;
  }

  .group-photo {
    height: 10%px;
    bottom: 0;
    right: 10%;
    width: auto; /* Adjust size for smaller screens */
  }

  .text-container {
    position: static;
    transform: none; /* Remove transform */
    width: 100%; /* Full width */
    margin: 10px ; /* Add margin */
  }

  .text-container h1 {
    font-size: 28px; /* Adjust font size */
    line-height: 36px; /* Adjust line height */
    margin-bottom: 10%; /* Reset margin */
  }

  .btn-consultation, .btn-faqs {
    font-size: 1.5em; /* Adjust button size */
    padding: 10px 20px; /* Adjust button padding */
  }
}
