@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.dropdown {
  position: relative;
  display: inline-block;
}

.menuIcon {
  background: none;
  border: none;
  font-size: 48px;
  cursor: pointer;
  color: white;
  transition: transform 0.3s ease-in-out;
}

.menuIcon.open {
  transform: rotate(0deg);
}

.menuContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(0, 40, 38, 0.9);
  border-radius: 8px;
  min-width: 160px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(-10px);
}

.menuContent.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.link {
  color: white;
  text-align: center;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.link:hover {
  font-weight: 700; /* Make text bolder on hover */
}
