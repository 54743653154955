/* General styles */
.third-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f7f4f2;
  padding-top: 5%;
  box-sizing: border-box;
}

.third-page-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.third-page-content h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  line-height: 60px;
  font-weight: 500;
  margin-bottom: 2px;
  color: #36120F;
}

.third-page-content p {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  line-height: 60px;
  font-weight: 500;
  margin-bottom: 2px;
  color: #36120F;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: -20px; /* Reduced from 20px to make columns closer */
  padding: 1px 5px; /* Reduced horizontal padding */
  max-width: 1200px;
  margin-left: 10%;
  margin-top: 5%;
}

.icon-column {
  flex-basis: calc(40% - 0px); /* Adjusted to reflect the new gap */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 200px;
  
}

.icon-item .icon-text {
  text-align: left; /* Align text to the left */
  width: 100%; /* Ensure text container uses the full available width */
  text-align: left;
  max-width: 100%; /* Ensures the text container doesn't exceed the column width */
}

.icon-item img {
  max-height: 200px;
  max-width: 200px;
  width: auto;
  height: auto;
}

.icon-item .icon-text h2 {
  margin-top: 30px;
  margin-bottom: 10px;
  /* Additional title styles can go here */
}

.icon-text {
  width: 100%;
}

.icon-text p {
  font-size: 20px; /* Adjust the font size to be slightly smaller */
  line-height: normal;
  text-align: left; /* Align text to the left */
  margin-left: 0; /* Ensure no indentation */
  padding-left: 0; /* In case padding is causing indentation */
}

/* Individual icon size classes */
.icon-size-personalized-medicine {
  margin-top: 350px;
}

/* Add additional icon size classes here, following the pattern of .icon-size-nutrition */

.third-page-buttons {
  margin-top: -5%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0px 0; /* Adjust padding as needed */
  /* Remove bottom and position if not necessary */
  margin-bottom: 20%;
}

/* Button styles with overrides and hover effects */
.third-page-buttons button {
  padding: 15px 30px;
  font-size: 1.5em;
  border: 2px solid #bb7912;
  color: #bb7912;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
}

.third-page-buttons button.our-services {
  background-color: #bb7912;
  color: white;
}

.third-page-buttons button:hover {
  background-color: #bb7912;
  color: white;
  border-color: #bb7912; /* Unify hover effects */
}

.third-page-buttons button.our-services:hover {
  background-color: transparent;
  color: #bb7912; /* Unify hover effects */
}

.see-more-button {
  display: none;
}

/* Media queries for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .third-page-content h1 {
    font-size: 36px; /* Adjust font size for tablets */
    line-height: 48px;
  }

  .third-page-content p {
    font-size: 22px; /* Adjust font size for tablets */
    line-height: 36px;
  }

  .icons-container {
    justify-content: center; /* Center icons container */
    gap: 10px; /* Adjust gap between icons */
    margin-left: 0; /* Remove margin for tablets */
  }

  .icon-column {
    flex-basis: 100%; /* Full width for icons */
  }

  .icon-item {
    margin-bottom: 100px; /* Adjust margin for tablets */
  }

  .third-page-buttons {
    margin-top: 5%;
    margin-bottom: 10%;
  }

  .third-page-buttons button {
    padding: 12px 24px; /* Adjust padding for buttons */
    font-size: 1.2em; /* Adjust font size for buttons */
  }
}

/* Media queries for mobile (max-width: 480px) */
/* Media queries for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .icons-container {
    flex-direction: column; /* Stack icons in a single column */
    align-items: center; /* Center align icons */
    margin-left: 0; /* Remove margin for mobile */
    gap: 0; /* Remove gap between icons */
  }

  .icon-column:first-of-type {
    display: flex; /* Ensure the first column is always displayed */
    margin-bottom: 0; /* Remove bottom margin */
  }
  .third-page-content h1 {
    text-align: center;
  }
  .icon-column:nth-of-type(2) {
    display: none; /* Hide the second column by default */
    margin-top: 0; /* Remove any top margin */
    padding-top: 0;
  }

  .icon-column.show {
    display: flex; /* Show the second column when the 'show' class is added */
  }

  .icon-item {
    margin-bottom: 20px; /* Adjust margin for mobile, reduce if needed */
    align-items: center; /* Center align individual icons */
  }

  .icon-column.show .icon-item:first-child {
    margin-top: 0; /* Ensure no top margin for the first item in the shown column */
  }

  /* Individual icon size classes */
  .icon-size-personalized-medicine {
    margin-top: 20px;
  }


  .icon-item .icon-text {
    text-align: center; /* Center align text */
  }

  .icon-item .icon-text p {
    text-align: center; /* Center align text */
  }

  .icon-item img {
    max-height: 100px;
    max-width: 100px;
    width: auto;
    height: auto;
  }

  .see-more-button {
    display: block; /* Show button only on mobile */
    margin-top: 20px;
    font-size: 1em;
    border: 2px solid #bb7912;
    color: #bb7912;
    background: transparent;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 60px;
    padding: 10px 20px;
  }
}
