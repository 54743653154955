.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    background-color: #bb7912;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, opacity 0.3s;
    z-index: 1000;
    opacity: 0; /* Initially hidden */
    visibility: hidden; /* Initially hidden */
  }
  
  .floating-button:hover {
    background-color: #a66b10;
    transform: scale(1.1);
  }
  
  .floating-button.visible {
    opacity: 1; /* Show when scrolled down */
    visibility: visible; /* Show when scrolled down */
  }
  
  /* Media query to ensure button only appears on mobile */
  @media (min-width: 769px) {
    .floating-button {
      display: none;
    }
  }
  