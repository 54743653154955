.about-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #002826;
  color: white;
  max-width: 100%;
  margin: auto;
  padding: 20px; /* Add some padding for better spacing on smaller screens */
}

.about-button {
  padding: 15px 30px;
  font-size: 1.5em;
  border: 2px solid #bb7912;
  color: #bb7912;
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 60px;
  margin: 10px 0; /* Adjust margin for spacing on smaller screens */
}

.about-button:hover {
  background: transparent;
  color: white;
  border: solid white 2px;
}

.about-text {
  text-align: left;
  margin-top: 10%;
  margin-bottom: 100px;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
}

.about-text h2 {
  margin-bottom: 10%;
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  line-height: 25px;
  font-weight: 500;
}

.about-text p {
  font-size: 18px; /* Added px unit */
  margin-bottom: 10%;
  line-height: 26px;
  font-family: 'Open Sans', sans-serif;
  color: #E9E3DC;
}

.about-image-quote {
  display: flex;
  align-items: center; /* Center-align the headshot and the quote vertically */
  justify-content: center; /* Center the content horizontally */
  width: 100%; /* Take the full width of the container */
  margin-top: 20px; /* Space between text and image/quote */
  color: #E9E3DC;
  font-family: 'Poppins', sans-serif;
}

.headshot {
  z-index: 1;
  margin-left: 10%;
  margin-top: 0%;
  max-width: 30%; /* Adjust based on your actual image size */
  margin-right: 20px; /* Space between image and quote */
  margin-bottom: 0px;
}

.about-quote {
  z-index: 1;
  width: 100%;
  margin-left: 15%;
  margin-right: 15%;
}

.custom-about-blockquote {
  font-size: 28px; /* Adjust if necessary to match the exact size */
  line-height: 38px;
  font-weight: 300;
  color: #c5c0bb;
  font-family: 'Poppins', sans-serif;
  text-align: left; /* Align text left */
  margin: 0; /* For centering the block horizontally if needed */
  width: 100%; /* Adjust based on your layout's needs */
  display: block; /* To make sure it behaves as a block-level element */
  margin-bottom: 10%;
}

cite {
  margin-top: 20%;
}

.shape-large {
  position: absolute;
  bottom: 0%; /* Adjust as needed */
  left: 40%; /* Adjust as needed */
  width: 20%; /* Size of the large circle */
  height: auto; /* Size of the large circle */
  z-index: 0; /* Ensure it does not overlap important content */
}

.shape-medium {
  position: absolute;
  bottom: 40%; /* Adjust as needed */
  right: 20%; /* Adjust as needed */
  width: 5%; /* Size of the small circle */
  height: auto; /* Size of the small circle */
  z-index: 0;
}

.shape-small {
  position: absolute;
  top: 10%; /* Adjust based on the overall layout */
  right: 0%; /* Adjust based on the overall layout */
  width: 15%; /* Size of the ellipse */
  height: 60%; /* Size of the ellipse */
  z-index: 0;
}

/* Media queries for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .about-text {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
  }

  .about-text h2 {
    font-size: 36px;
    line-height: 45px;
  }

  .about-text p {
    font-size: 16px;
    line-height: 24px;
  }

  .about-image-quote {
    flex-direction: column;
    margin-top: 30px;
  }

  .headshot {
    margin: 0 auto 20px auto; /* Center the image and add bottom margin */
    max-width: 50%; /* Adjust for tablet size */
  }

  .about-quote {
    max-width: 90%;
    text-align: center;
  }
}

/* Media queries for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .about-text {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }

  .about-text h2 {
    font-size: 28px;
    line-height: 35px;
  }

  .about-text p {
    font-size: 14px;
    line-height: 20px;
    z-index: 2;
  }

  .about-image-quote {
    flex-direction: column;
    margin-top: 20px;
  }

  .headshot {
    margin: 0 auto 20px auto; /* Center the image and add bottom margin */
    max-width: 70%; /* Adjust for mobile size */
  }

  .about-quote {
    max-width: 100%;
    text-align: center;
  }

  .custom-about-blockquote {
    font-size: 20px;
    line-height: 30px;
  }

  .shape-small {
    position: absolute;
    top: 20%; /* Adjust based on the overall layout */
    right: 0%; /* Adjust based on the overall layout */
    width: auto; /* Size of the ellipse */
    height: 20%; /* Size of the ellipse */
    z-index: 0;
  }

  .shape-large {
    position: absolute;
    bottom: 0%; /* Adjust as needed */
    left: 10%; /* Adjust as needed */
    width: 20%; /* Size of the large circle */
    height: auto; /* Size of the large circle */
    z-index: 0; /* Ensure it does not overlap important content */
  }
  
}
