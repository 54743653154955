/* General styles */
.aboutUsContainer {
  position: relative;
  color: white;
  font-family: 'Arial', sans-serif;
  background-color: #002826; /* Dark teal background */
  padding: 40px;
  max-width: 100%; /* Adjust based on your layout requirements */
  margin: auto;
  padding-bottom: 200px;
}

.aboutUsContainer img {
  position: absolute;
}

.aboutUsContainer h1 {
  font-size: 2.5em; /* Large font size for the header */
  color: #ffffff;
  margin-bottom: 20px;
  margin-left: 15%; /* Starts text 10% from the left edge of the container */
}

.aboutUsContainer p {
  font-family: 'Poppins', sans-serif;
  font-size: 28px;
  font-weight: 100;
  line-height: 38px;
  text-align: left;
  color: #E9E3DC;
  margin-left: 15%; /* Starts text 10% from the left edge of the container */
  margin-right: 35%; /* Ensures the text block does not extend beyond 50% from the left edge */
  margin-bottom: 20px; /* Space between paragraphs */
}

/* Positioning specific images based on your paths and description */
.large-green-semi-circle {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 75%;
  width: auto;
  z-index: 0;
}

.highlight {
  color: #D9861F; /* Color similar to the yellow in the image */
}

.yellow-circle-position {
  position: absolute;
  right: 23%; /* Adjust this value as needed */
  top: 30%; /* Adjust this value as needed */
  width: 35px;
  height: auto;

}

.about-button-container {
  text-align: center;
  margin-top: 5%;
 
}

.about-cta-button {
  position: relative;
  z-index: 4;
  font-size: 24px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
  border: 2px solid #bb7912;
  background-color: #bb7912;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
  margin-right: 10px;
}

.about-cta-button:hover {
  background-color: transparent;
  color: #bb7912;
  font-weight: 600;
  border: 2px solid #bb7912;
}


.yellow-semi-circle {
  position: absolute;
  right: 0;
  top: 10%;
  height: 250px;
  width: auto;
}

/* Responsive design adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .aboutUsContainer h1 {
    font-size: 2em; /* Adjust font size for tablets */
    margin-left: 10%; /* Adjust left margin for tablets */
    margin-right: 10%; /* Adjust right margin for tablets */
  }

  .aboutUsContainer p {
    font-size: 24px; /* Adjust font size for tablets */
    line-height: 32px;
    margin-left: 10%; /* Adjust left margin for tablets */
    margin-right: 10%; /* Adjust right margin for tablets */
  }

  .large-green-semi-circle {
    height: 50%; /* Adjust size for tablets */
  }

  .yellow-circle-position {
    width: 25px; /* Adjust size for tablets */
  }

  .yellow-semi-circle {
    height: 200px; /* Adjust size for tablets */
  }
}

/* Responsive design adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .aboutUsContainer{
    padding: 20px;
    padding-top: 7vh;

  }

  .about-cta-button {
    font-size: 16px;
  }

  .aboutUsContainer h1 {
    font-size: 1.5em; /* Adjust font size for mobile */
    margin-left: 0%; /* Center text for mobile */
    margin-right: 0%; /* Center text for mobile */
    text-align: left; /* Center align text for mobile */
  }

  .aboutUsContainer p {
    font-size: 18px; /* Adjust font size for mobile */
    line-height: 24px;
    margin-left: 0%; /* Center text for mobile */
    margin-right: 0%; /* Center text for mobile */
    text-align: left; /* Center align text for mobile */
  }

  .large-green-semi-circle {
    height: 40%; /* Adjust size for mobile */
  }

  .yellow-circle-position,
  .yellow-semi-circle {
    display: none; /* Hide elements for better mobile layout */
  }
}
