/* IntroText.css */

.hero-section {
  position: relative;
  background-size: cover;
  background-position: center;
  background-color: #002826;
  height: 100vh;
  color: white;
  text-align: center;
  max-width: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}

.home-page-intro-content {
  position: absolute;
  top: 12%;
  width: 100%;
  text-align: center;   
}

.home-page-intro-content h1 {
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  line-height: 78px;
  font-weight: 500;
  margin-bottom: 2px;
}

.home-page-intro-content p {
  margin-bottom: 1em;
  font-family: 'Poppins', sans-serif;
  font-size: 46px;
  line-height: 62px;
  font-weight: 400;
}

.cta-button {
  font-size: 24px;
  padding: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 500;
  border: 2px solid #bb7912;
  background-color: #bb7912;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
  margin-right: 10px;
}

.cta-button:hover {
  background-color: transparent;
  color: white;
  font-weight: 600;
  border: 5px solid #bb7912;
}

.person-image {
  position: absolute;
  bottom: 0%;
  height: auto;
  width: 80%;
  min-width: 1000px;
}

/* Media queries for mobile */
@media (max-width: 768px) {
  .hero-section {
    height: 60vh; /* Reduce height for tablets */
  }

  .home-page-intro-content h1 {
    font-size: 36px; /* Adjust font size for tablets */
    line-height: 56px;
  }

  .home-page-intro-content p {
    font-size: 36px; /* Adjust font size for tablets */
    line-height: 52px;
  }

  .cta-button {
    font-size: 20px; /* Adjust button font size */
    padding: 10px 20px; /* Adjust button padding */
  }

  .person-image {
    width: 70%; /* Adjust image width for tablets */
    min-width: auto;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 90vh; /* Reduce height for mobile */
  }

  .home-page-intro-content h1 {
    font-size: 28px; /* Adjust font size for mobile */
    line-height: 42px;
  }

  .home-page-intro-content p {
    font-size: 28px; /* Adjust font size for mobile */
    line-height: 42px;
  }

  .cta-button {
    font-size: 18px; /* Adjust button font size */
    padding: 8px 16px; /* Adjust button padding */
  }

  .person-image {
    width: 160%; /* Adjust image width for mobile */
    min-width: auto;
  }
}
