.second-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  align-items: center; /* Center items horizontally */
  width: 100%;
  background-color: #e9e3dc;
  padding-top: 5%; /* Adjust the top padding as necessary */
  box-sizing: border-box;
}

.second-page-content {
  width: 100%; /* Full width */
  max-width: 100%; /* Adjust to the desired content width */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;
}

h1 {
  margin: 0 0 16px; /* Adjust margins as necessary */
  font-size: 2.5rem; /* Adjust font size as needed */
}

p {
  margin: 0 0 32px; /* Adjust margins as needed */
  font-size: 1.5rem; /* Adjust font size as needed */
}

.service-paragraph {
  max-width: 60%;
  padding-top: 50px;
  margin: auto;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
}

.second-page-buttons {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center; /* Center buttons horizontally */
  gap: 20px; /* Adjust the gap between buttons */
}

.second-page-buttons button {
  padding: 15px 30px; /* Double the padding */
  font-size: 1.5em; /* Increase the font size */
  border: 2px solid #bb7912; /* Color for the border based on your screenshot */
  color: #bb7912; /* Button text color */
  background: transparent;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 60px;
}

.second-page-buttons button.our-services {
  background-color: #bb7912; /* Background color for the solid button */
  color: white; /* Text color for the solid button */
}

.second-page-buttons button:hover {
  background-color: #bb7912; /* Change background on hover */
  color: white; /* Change text color on hover */
  border-color: #bb7912; /* Ensure the border color stays consistent on hover */
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%; /* Make the container take the full width */
  padding: 0 10%; /* Add padding to adjust spacing */
  box-sizing: border-box; /* Ensure padding is included in width */
  height: auto;
  justify-items: center; /* Center items horizontally within the grid */
}

.card {
  background-color: transparent;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  border: solid #36120F;
  width: 100%; /* Use 100% to allow responsiveness */
  max-width: 550px; /* Set a maximum width for the cards */
  height: 300px; /* Set a fixed height for the cards */
  align-items: center;
  justify-items: center; /* Center items horizontally within the grid */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none; /* Remove underline from links */
}

.service-paragraph {
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;
  font-family: 'Poppins'; /* Now using Poppins */
  color: #36120F;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(211, 15, 15, 0.1);
}

.card img {
  max-width: 185px;
  max-height: 150px;
  height: auto;
  width: auto;
  margin-bottom: 10px;
}

.card p {
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  font-family: 'Poppins'; /* Now using Poppins */
  color: #36120F;
}

/* Responsive design for tablets */
@media (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr; /* Stack cards vertically on smaller screens */
    width: 90%; /* Adjust the width for smaller screens */
    padding: 0 5%; /* Adjust padding for smaller screens */
  }

  .card {
    width: 100%; /* Adjust the width for smaller screens */
    height: auto; /* Adjust the height for smaller screens */
  }

  .card img {
    max-width: 80px;
    max-height: 80px;
  }

  .card p {
    font-size: 12px;
  }
}

/* Responsive design for mobile */
@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
  }

  .service-paragraph {
    font-size: 1rem;
    padding-top: 50px;
  }

  .second-page-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .second-page-buttons button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
  }

  .cards-container {
    grid-template-columns: 1fr; /* Stack cards vertically on mobile */
    gap: 10px;
    width: 100%;
    padding: 0 5%; /* Adjust padding for mobile screens */
  }

  .card {
    width: 100%; /* Adjust the width for mobile screens */
    height: auto; /* Adjust the height for mobile screens */
  }

  .card img {
    max-width: 60px;
    max-height: 60px;
  }

  .card p {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    font-family: 'Poppins'; /* Now using Poppins */
    color: #36120F;
  }
  .service-paragraph {
    max-width: 100%;
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
    font-family: 'Poppins'; /* Now using Poppins */
    color: #36120F;
    text-align: center;
  }
}
