/* General styles */
.doctor-profile {
  background-color: #f9f9f9;
  width: 90%; /* Make the width responsive */
  max-width: 1000px; /* Ensure it doesn't exceed a certain width */
  padding: 50px; /* Adjust padding */
  border-radius: 8px;
  border: 1px solid black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 50px auto; /* Center and add space between profiles */
  display: flex;
  flex-direction: column; /* Stack children vertically */
}

.doctor-profile h1 {
  font-size: 28px; /* Larger font size for the title */
  color: #004D49;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif; /* Now using Poppins */
}

.profile-and-credentials {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px; /* Space between this section and the paragraph */
  display: flex;
  align-items: center; /* Center items vertically within the row */
}

.profile-photo {
  width: 150px;
  height: auto;
  border-radius: 10px;
  margin-right: 20px;
}

.credentials {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Take remaining space */
}

.credentials div {
  font-size: 16px;
  color: #36120F;
  margin-bottom: 10px; /* Spacing between credential items */
}

.credential-text {
  border-bottom: 1px solid #36120F;
  padding-bottom: 2px; /* Adds space between the text and the underline */
  display: inline-block;
}

.doctor-profile p {
  text-align: justify;
  margin: 0; /* Adjust as necessary */
  font-family: 'Open Sans', sans-serif; /* Ensure Open Sans is loaded or available */
  font-size: 18px; /* Size as specified */
  line-height: 26px; /* Line height as specified */
  color: #36120F;
}

/* Toggle button for mobile */
.toggle-button {
  display: none;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #004D49;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive design adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .doctor-profile {
      width: 90%; /* Adjust width for tablets */
      padding: 30px; /* Adjust padding for tablets */
  }

  .profile-and-credentials {
      flex-direction: column;
      align-items: center; /* Center align for tablets */
  }

  .profile-photo {
      margin-bottom: 20px; /* Space between photo and credentials for tablets */
      margin-right: 0; /* Remove right margin */
  }

  .credentials div {
      font-size: 14px; /* Adjust font size for tablets */
  }
}

/* Responsive design adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .doctor-profile {
      width: 80%; /* Full width for mobile */
      padding: 20px; /* Adjust padding for mobile */
  }

  .doctor-profile h1 {
      margin-bottom: 5px;
  }

  .profile-and-credentials {
      flex-direction: column;
      align-items: center; /* Center align for mobile */
      padding: 10px; /* Reduce padding for mobile */
  }

  .profile-photo {
      width: 100%; /* Adjust size for mobile */
      margin-bottom: 10%; /* Space between photo and credentials for mobile */
      margin-right: 0; /* Remove right margin */
  }

  .credentials div {
      font-size: 16px; /* Adjust font size for mobile */
  }

  .profile-and-credentials {
      margin-bottom: 0%;
  }

  .toggle-button {
      display: block; /* Show button on mobile */
      margin-top: 2%;
      margin-bottom: 5%;
      font-size: 18px;
      padding: 10px;
      width: 50%;
      margin-left: 25%;
      font-weight: 500;
      border: 2px solid #bb7912;
      background-color: #bb7912;
      color: white;
      transition: all 0.3s ease;
      border-radius: 60px;
  }

  .doctor-description {
      display: none; /* Hide description by default on mobile */
  }

  .doctor-description.show {
      display: block; /* Show description when expanded */
  }

  .doctor-profile p {
      font-size: 16px;
  }
}
