/* General styles for .comparison-grid-container */
.comparison-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal columns */
  gap: 20px;
  background-color: #e9e3dc;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  width: 100%; /* Full-width for grid container */
  padding-bottom: 10%;
}

.header-section {
  grid-column: 1 / -1; /* Header spans all columns */
  display: flex;
  max-width: 60%;
  margin: auto;
  gap: 10%;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 5%;
}

.header-text {
  flex: 1;
  padding: 0 20px;
  width: 30%;
}

.header-text h2 {
  font-family: 'Poppins'; /* Now using Poppins */ 
  font-size: 28px;
  color: #36120F;
  font-weight: 400;
  line-height: 38px;
}

.feature-section {
  width: 60%; /* Reduce width to allow centering */
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  margin: 2% auto;  /* This will center the section horizontally */
  margin-left: 30vh;
}

.feature-text {
  margin-top: 23%;
  width: 400px;
  padding-bottom: 0px; /* Space between text and underline */
  margin-bottom: 20px; /* Space between each feature */
}

.feature-text p {
  padding-top: 14px;     /* Space above the text */
  padding-bottom: 14px;  /* Space below the text */
  margin-bottom: 0;      /* Removes extra space below each paragraph */
  border-top: 1px solid black; /* Line above each paragraph */
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: #36120F;
}

.feature-column img {
  width: 200px; /* Ensure images fill their containers */
  height: auto; /* Maintain aspect ratio */
  margin-left: 40px;
}

/* Responsive design adjustments for tablets (max-width: 768px) */
@media (max-width: 768px) {
  .comparison-grid-container {
      grid-template-columns: 1fr 1fr 1fr; /* Maintain three columns */
      padding-bottom: 5%;
  }

  .header-section {
      flex-direction: column;
      max-width: 90%;
  }

  .header-text {
      width: 100%;
      padding: 0;
  }

  .header-text h2 {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
  }

  .feature-section {
      grid-template-columns: 2fr 1fr 1fr;
      margin-left: 0;
  }

  .feature-text {
      width: 100%;
      margin-top: 5%;
      text-align: center; /* Center-align text */
  }

  .feature-text p {
      font-size: 16px;
      line-height: 24px;
  }

  .feature-column img {
      width: 150px;
      margin-left: 0; /* Center-align images */
      display: block;
      margin: 10px auto; /* Center the images and add margin */
  }
}

/* Responsive design adjustments for mobile (max-width: 480px) */
@media (max-width: 480px) {
  .comparison-grid-container {
    display: none;
  }

  .header-section {
      flex-direction: column;
      max-width: 95%;
  }

  .header-text {
      width: 100%;
      padding: 0;
  }

  .header-text h2 {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
  }

  .feature-section {
      grid-template-columns: 1fr 1fr 1fr; /* Single column layout */
      margin-left: 0;
  }

  .feature-text {
      width: 100%;
      margin-top: 5%;
      text-align: center; /* Center-align text */
  }

  .feature-text p {
      font-size: 14px;
      line-height: 20px;
  }

  .feature-column img {
      width: 120px;
      margin-left: 0; /* Center-align images */
      display: block;
      margin: 10px auto; /* Center the images and add margin */
  }
}
